// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  // Add your Vuetify configuration options here
  // Example:
  // icons: {
  //   iconfont: 'mdi', // default - only for display purposes
  // },
});
